<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <PageHeader title="Inventory Sources Overview"></PageHeader>

    <DataTable
      table-key="InventorySourcesOverview"
      row-key="id"
      title="Sources"
      :columns="columns"
      :rows="inventoryFeeds"
      :loading="loading"
      :pagination="{
        sortBy: 'id',
        descending: false,
        page: 1,
        rowsPerPage: 25
      }"
      :downloadable="true"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <template v-slot:paused="row">
        <q-icon
          :name="row.paused == 1 ? 'pause_circle' : 'check_circle'"
          size="sm"
          :color="row.paused == 1 ? 'negative' : 'positive'"
          class="flex q-mr-sm"
        >
          <q-tooltip
            anchor="center right"
            self="center left"
            :offset="[10, 10]"
          >
            <span v-if="row.paused == 1">Paused - {{ row.paused_reason }}</span>
            <span v-else>Active</span>
          </q-tooltip>
        </q-icon>
      </template>
      <template v-slot:products_active_total="row">
        <router-link
          :to="{
            path: '/inventory/products/' + row.product_type.toLowerCase() + 's',
            query: {
              business_id: row.business_id,
              inventory_source_id: row.id
            }
          }"
        >
          {{ Intl.NumberFormat().format(row.products_active_total) }}
        </router-link>
      </template>
      <template v-slot:actions="row">
        <ActionsDropdownButton
          :actions="[
            {
              action: () =>
                $router.push({
                  path: '/inventory/sources/history/' + row.id
                }),
              label: 'Import History'
            }
          ]"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import moment from "moment";
import PageHeader from "@/components/UI/PageHeader";
import axios from "axios";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton";
import DataTable from "@/components/DataTable";
moment.locale("en");

export default {
  name: "BusinessInventoryFeedsOverview",
  components: {
    DataTable,
    ActionsDropdownButton,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      loading: true,
      columns: [
        {
          name: "paused",
          label: "Status",
          field: "paused",
          hideField: true,
          format: val => (val == 1 ? "Paused" : "Active"),
          filterable: true
        },
        {
          name: "id",
          label: "Feed ID",
          field: "id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "name",
          label: "Feed Name",
          field: "name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "business_id",
          label: "Business ID",
          field: "business_id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "business_name",
          label: "Business Name",
          field: "business_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "product_type",
          label: "Product Type",
          field: "product_type",
          sortable: true,
          type: "dimension"
        },
        {
          name: "protocol",
          label: "Source",
          field: "protocol",
          format: val => this.formatSourceLabel(val),
          sortable: true,
          type: "dimension"
        },
        {
          name: "filename",
          label: "Filename",
          field: "filename",
          format: val => (val.length > 40 ? val.substr(0, 37) + "..." : val),
          sortable: true,
          type: "dimension"
        },
        {
          name: "products_active_total",
          label: "Inv. Count",
          field: "products_active_total",
          hideField: true,
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "time_last_imported",
          label: "Last Imported",
          field: row =>
            row.time_last_imported
              ? moment.utc(row.time_last_imported).local()
              : "",
          format: val => (!val ? "-" : moment(val).format("lll")),
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          type: "metric",
          metricType: "date"
        },
        {
          name: "actions",
          label: "",
          field: "",
          sortable: false,
          filterable: false,
          align: "right"
        }
      ]
    };
  },
  computed: {
    inventoryFeeds() {
      return this.loading ? [] : this.feeds;
    }
  },
  beforeMount() {
    let initialUrlParams = new URLSearchParams(window.location.search);

    let initialFilteredBusinessId = initialUrlParams.get("business_id");
    if (initialFilteredBusinessId) {
      window.history.replaceState(history.state, "", "?");

      this.columns = this.columns.map(column => {
        if (column.name === "business_id") {
          column.filterValues = {
            in: [initialFilteredBusinessId],
            notIn: [],
            gt: "",
            lt: ""
          };
          column.activeFilterCount = 1;
        }

        return column;
      });
    }

    this.columns = this.columns.map(column => {
      column.classes = row => (row.paused == 1 ? "bg-red-1" : "");
      /*if (!initialFilteredBusinessId && column.name === "paused") {
        column.filterValues = {
          in: ["0"],
          notIn: [],
          gt: "",
          lt: ""
        };
        column.activeFilterCount = 1;
      }*/
      return column;
    });
  },
  mounted() {
    axios({
      url: "https://api-v2.jumpfeed.com/internal/inventory-sources"
    })
      .then(resp => {
        this.feeds = resp.data.data;
        this.loading = false;
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    formatSourceLabel(text) {
      if (["crawl"].includes(text)) {
        return text.charAt(0).toUpperCase() + text.slice(1);
      } else {
        return text.toUpperCase();
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
